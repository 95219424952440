import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';
import { callApiClient } from '../services/api/callService';
import { documentApiClient } from '../services/api/documentService';
import { formsApiClient } from '../services/api/formsService';
import { mattersApiClient } from '../services/api/mattersService';
import { userApiClient } from '../services/api/userService';
import matterReducer from './reducers/matter';
import rootReducer from './reducers/root';

// Create the Sentry Redux enhancer
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    matters: matterReducer,
    root: rootReducer,
    [documentApiClient.reducerPath]: documentApiClient.reducer,
    [mattersApiClient.reducerPath]: mattersApiClient.reducer,
    [userApiClient.reducerPath]: userApiClient.reducer,
    [callApiClient.reducerPath]: callApiClient.reducer,
    [formsApiClient.reducerPath]: formsApiClient.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(mattersApiClient.middleware)
      .concat(userApiClient.middleware)
      .concat(documentApiClient.middleware)
      .concat(callApiClient.middleware)
      .concat(formsApiClient.middleware),
  devTools: process.env.NODE_ENV !== 'production',
  // TODO security audit - determine what we need to redact before sending to sentry
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
