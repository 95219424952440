import { createApi } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { EngagementLetterFields } from '../types/document-types';
import { baseQueryWithErrorHandling } from './baseQuery';

// TODO joanie switch back to these from fetch APIs
export const documentApiClient = createApi({
  reducerPath: 'documentApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [
    'TranscriptPdf',
    'EngagementPdf',
    'FormPdf',
    'EngagementLetter',
  ] as const,
  endpoints: (builder) => ({
    getTranscriptPdf: builder.query<unknown, string>({
      query: (conversationId) => `api/transcript/${conversationId}/pdf/`,
      providesTags: (result, error, conversationId) => [
        { type: 'TranscriptPdf', id: conversationId },
      ],
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
    getEngagementPdf: builder.query<unknown, string>({
      query: (conversationId) => `api/documents/engagement/${conversationId}`,
      providesTags: (result, error, conversationId) => [
        { type: 'EngagementPdf', id: conversationId },
      ],
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
    getFormPdf: builder.query<unknown, string>({
      query: (formId) => `api/documents/form/${formId}`,
      providesTags: (result, error, formId) => [
        { type: 'FormPdf', id: formId },
      ],
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
    getEngagementFields: builder.query<EngagementLetterFields, string>({
      query: (matterId) => ({
        url: `api/matters/${matterId}/engagement_letter_fields`,
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
    }),
    getRecentEngagementLetter: builder.query<
      { id: string; status: 'SENT' | 'CREATED' | 'COMPLETED' | 'VOID' },
      string
    >({
      query: (matterId) => ({
        url: `api/matters/${matterId}/engagement_letter/recent`,
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
      providesTags: (result, error, matterId) => [
        { type: 'EngagementLetter', id: matterId },
      ],
    }),
    getEngagementLetterPreview: builder.query<
      Blob,
      { matterId: string; documentInstanceId: string }
    >({
      query: ({ matterId, documentInstanceId }) => ({
        url: `api/matters/${matterId}/engagement_letter/${documentInstanceId}/preview`,
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    createEngagementLetter: builder.mutation<
      unknown,
      {
        matterId: string;
        fields: Record<string, string>;
      }
    >({
      query: ({ matterId, fields }) => ({
        url: `api/matters/${matterId}/engagement_letter`,
        method: 'POST',
        body: fields,
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'EngagementLetter', id: matterId },
      ],
    }),
    sendEngagementLetter: builder.mutation<
      { document_instance_id: string },
      { matterId: string; engagementLetterId: string }
    >({
      query: ({ matterId, engagementLetterId }) => ({
        url: `api/matters/${matterId}/engagement_letter/${engagementLetterId}/send`,
        method: 'POST',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),

      invalidatesTags: (result, error, { matterId }) => [
        { type: 'EngagementLetter', id: matterId },
      ],
    }),

    resendEngagementLetter: builder.mutation<
      {
        document_instance_id: string;
        agreement_id: string;
      },
      { matterId: string; engagementLetterId: string }
    >({
      query: ({ matterId, engagementLetterId }) => ({
        url: `api/matters/${matterId}/engagement_letter/${engagementLetterId}/re_send`,
        method: 'POST',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'EngagementLetter', id: matterId },
      ],
    }),
  }),
});

export const {
  useGetTranscriptPdfQuery,
  useGetEngagementPdfQuery,
  useGetFormPdfQuery,
  useGetEngagementFieldsQuery,
  useGetRecentEngagementLetterQuery,
  useGetEngagementLetterPreviewQuery,
  useCreateEngagementLetterMutation,
  useSendEngagementLetterMutation,
  useResendEngagementLetterMutation,
} = documentApiClient;
